import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Clarity from '@microsoft/clarity';
import { Toaster } from 'react-hot-toast';
import Router from './routes';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
  }, []);

  return (
    <div className="App relative text-white bg-dark bg-repeat-x font-filsonPro-light overflow-hidden">
      <Toaster
        position="buttom-center"
        reverseOrder={false}
        gutter={12}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className:
            '!text-[1.2rem] !text-white !bg-[rgba(0,2,29,0.7)] !border-2 !border-gray-700 !max-w-fit !font-filsonPro-light',
          duration: 5000,
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          }
        }}
      />
      <Router />
    </div>
  );
}

export default App;
